<template>
	<div>
        <DataTable :value="customers" :lazy="true" :paginator="true" :rows="100" v-model:filters="filters" ref="dt"
            :totalRecords="totalRecords" :rowsPerPageOptions="[100,200,500,1000]" :loading="loading" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)" filterDisplay="row"
            responsiveLayout="scroll">
            <Column field="name" header="Name" filterField="name" filterMatchMode="like" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>
            <Column field="country" header="Country" filterField="country" filterMatchMode="like" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by country"/>
                </template>
                <template #body="row">
                    <div>{{row.data.country.toUpperCase()}}</div>
                </template>
            </Column>
            <Column field="company" header="Company" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div style="width: 200px">
                        <input type="text" class="form-control" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="Search in company">
                    </div>
                </template>
            </Column>
            
        </DataTable>
	</div>
</template>

<script>
// import CustomerService from './service/CustomerService';

export default {
    data() {
        return {
            loading: false,
            totalRecords: 0,
            customers: null,
            filters: {
                'name': {value: '', matchMode: 'in'},
                'country': {value: '', matchMode: 'in'},
                'company': {value: '', matchMode: 'in'},
            },
            lazyParams: {},
        }
    },
    customerService: null,
    created() {
        // this.customerService = new CustomerService();
    },
    mounted() {
        this.loading = true;
        
        this.lazyParams = {
            first: 0,
            rows: this.$refs.dt.rows,
            sortField: null,
            sortOrder: null,
            filters: this.filters
        };

        this.loadLazyData();
    },
    methods: {
        loadLazyData() {
            this.loading = true;
            console.log(this.lazyParams);

            setTimeout(() => {
                // this.customerService.getCustomers(
                //     {lazyEvent: JSON.stringify( this.lazyParams )})
                //         .then(data => {
                //             this.customers = data.customers;
                //             this.totalRecords = data.totalRecords;
                //             this.loading = false;
                //     }
                // );
                this.customers = [
                    {name: 'Asif', 'country': 'India', 'company': 'iCraftSolutions'},
                    {name: 'Sumon', 'country': 'India', 'company': 'Labsys'}
                ];
                for(let i=0; i<50;i++){
                    this.customers.push({name: 'Asif '+i, 'country': 'India', 'company': 'iCraftSolutions'})
                }
                this.totalRecords = this.customers.length;
                

                this.customers = this.customers.slice(this.lazyParams.first, this.lazyParams.first+this.lazyParams.rows);

                this.loading = false;
            }, Math.random() * 1000 + 250);
        },
        onPage(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onSort(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onFilter() {
            this.loading = true;
            this.lazyParams.filters = this.filters;
            this.loadLazyData();
        }
    }
}
</script>